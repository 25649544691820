<template>
  <UiPopup
    :model-value="modelValue"
    :title="$t('confirm_payment')"
    :description="$t('confirm_payment_description', { hours: holdingHours })"
    :primary-button-text="$t('send_confirmation')"
    :secondary-button-text="$t('close')"
    size="small"
    :loading="loading"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
    @close="emits('cancel')"
    @cancel="emits('cancel')"
  >
    <div class="mb-2 mt-10 flex flex-col gap-4 text-start">
      <div class="w-full gap-3 rounded-2xl border border-primary-30 p-4">
        <span class="text-subhead-2 text-primary-90">{{ $t('buyer_info') }}</span>
        <UiInputTextField
          v-model="confirmationData.name"
          name="buyer_name"
          :placeholder="$t('buyer_name')"
          class="mt-4"
          compact
          :error="useGetFieldErrors(v$, ['name'])"
        />
        <UiInputTextField
          v-model="confirmationData.email"
          name="buyer_email"
          :placeholder="$t('buyer_email')"
          class="mt-4"
          compact
          :error="useGetFieldErrors(v$, ['email'])"
        />
        <UiInputTextField
          v-model="confirmationData.address"
          name="buyer_address"
          :placeholder="$t('buyer_address')"
          class="mt-4"
          compact
          :error="useGetFieldErrors(v$, ['address'])"
        />
        <UiInputPhone
          v-model="confirmationData"
          name="Phone"
          :placeholder="!validatePhone() ? $t('add_number') : $t('number')"
          class="mt-4"
          compact
          :error="useGetFieldErrors(v$, ['phone_country_id', 'phone'])"
        />
      </div>
      <div class="rounded-lg bg-black-03 px-4 py-3">
        <div class="mb-2 flex min-h-10 max-w-full flex-row items-center gap-3">
          <div class="text-body-2 flex max-w-[170px] flex-1 flex-row items-center gap-1.5 text-black-60">
            <UiIcon class="shrink-0" name="link" size="xs" />
            <span class="text-nowrap">{{ $t('passport_copy') }}</span>
          </div>
          <div class="flex max-w-[calc(100%_-_170px)] flex-1 justify-end">
            <UiInputFile
              id="passport"
              v-model="confirmationData.passport"
              class="flex justify-end"
              compact
              icon="upload"
              :delete-locally="false"
              accept="image/*,application/pdf"
              :error="useGetFieldErrors(v$, ['passport'])"
              @file-removed="confirmationData.passport = null"
            />
          </div>
        </div>
        <div class="flex min-h-10 w-full items-center gap-3">
          <div class="text-body-2 flex max-w-[170px] flex-1 flex-row items-center gap-1.5 text-black-60">
            <UiIcon class="shrink-0" name="link" size="xs" />
            <span class="">{{ $t('payment_confirmation') }}</span>
          </div>
          <div class="flex max-w-[calc(100%_-_170px)] flex-1 justify-end">
            <UiInputFile
              id="payment"
              v-model="confirmationData.payment"
              class="flex justify-end"
              compact
              icon="upload"
              :delete-locally="false"
              accept="image/*,application/pdf"
              :error="useGetFieldErrors(v$, ['payment'])"
              @file-removed="confirmationData.payment = null"
            />
          </div>
        </div>
      </div>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import type { Lead } from '@/types'
import type { UnitPaymentToken } from '~/types/properties'
import { useUiStore } from '~/store/ui'

const emits = defineEmits(['update:modelValue', 'confirm', 'cancel'])

type Props = {
  modelValue: boolean
  holdingHours: string
  lead: Lead
  tokenId: UnitPaymentToken['id']
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const uiStore = useUiStore()

const loading = ref(false)

type ConfirmationData = {
  name: string
  email: string
  address: string
  passport?: null | File
  payment?: null | File
  phone: string | null | undefined
  phone_country_id: number | null
}
const confirmationData = ref<ConfirmationData>({
  name: props.lead?.name || '',
  email: props.lead.email || '',
  address: '',
  passport: null,
  payment: null,
  phone: (props.lead.phone as string) || null,
  phone_country_id: props.lead.phone_country_id || null,
})

const validatePhone = () => {
  return useValidatePhone(confirmationData.value.phone, confirmationData.value.phone_country_id)
}

const rules = computed(() => ({
  name: { required: helpers.withMessage('The name is required', required) },
  email: {
    required: helpers.withMessage('The email is required', required),
    email: helpers.withMessage('The email is not valid', email),
  },
  address: { required: helpers.withMessage('The address is required', required) },
  passport: { required: helpers.withMessage('The passport is required', required) },
  payment: { required: helpers.withMessage('The payment is required', required) },
  phone: {
    required: helpers.withMessage('Phone is required', required),
    validatePhone: helpers.withMessage('Phone number is not valid', validatePhone),
  },
  phone_country_id: {
    required: helpers.withMessage('Country code is required', required),
    validatePhone: helpers.withMessage('Phone number is not valid', validatePhone),
  },
}))

const v$ = useVuelidate<ConfirmationData>(rules, confirmationData)

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (!isValid) return
  try {
    const [passportFileId, paymentFileId] = await Promise.all([
      useUploadFile(confirmationData.value.passport as File, 'payment_token.passport'),
      useUploadFile(confirmationData.value.payment as File, 'payment_token.payment_confirmation'),
    ])
    const data = {
      ...confirmationData.value,
      files: [passportFileId, paymentFileId],
    }
    delete data.passport
    delete data.payment
    await useConfirmPayment(props.tokenId, data)
    uiStore.showSnackBanner('Success! Payment were confirmed.')
    emits('confirm')
    emits('update:modelValue', false)
  } catch {
    uiStore.showSnackBanner('Failed to confirm payment, please try again later', 'error')
  }
}
</script>

<style scoped></style>
